import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import NavbarBookmarks from "./NavbarBookmarks";
import { useWeb3React } from "@web3-react/core";
import { Row, Col, Button, Container, Navbar } from "reactstrap";
import { InjectedConnector } from "@web3-react/injected-connector";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];

  const injectedConnector = new InjectedConnector({
    supportedChainIds: [
      97, // Mainet
      56
    ]
  });

  const connect = () => {
    activate(
      injectedConnector,
      (error) => {
        console.log("error : ", error);
      },
      true
    ).then(() => {
      props.connected(account);
    });
  };

  const { activate, active, account } = useWeb3React();
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper flex align-items-center">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
                <Container>
                  <Row className="align-items-center">
                    <Col className="d-flex justify-content-end">
                      {active ? (
                        <div>{account}</div>
                      ) : props.wallet.kaikasConnected ? (
                        <>
                          <div className="mr-2">
                            Klaytn Account : {props.wallet.account}
                          </div>
                          <div>
                            <Button
                              color=""
                              className="pa-0"
                              onClick={() => props.kaikasDisconnected()}
                            >
                              <Icon.LogOut size={20} />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Button.Ripple
                            className="gradient-light-primary mr-2"
                            onClick={connect}
                          >
                            Connect Metamask
                          </Button.Ripple>
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={ userImg }
              /> */}
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = function (state) {
  return {
    wallet: state.wallet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    connected: (account) =>
      dispatch({ type: "WALLET_CONNECTED", account: account }),
    kaikasConnected: (account) =>
      dispatch({ type: "KAIKAS_CONNECTED", account: account }),
    disconnected: () => dispatch({ type: "WALLET_DISCONNECTED", account: {} }),
    kaikasDisconnected: () => dispatch({ type: "KAIKAS_DISCONNECTED" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar);
