import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  // {
  //   id: "home",
  //   title: "Home",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/"
  // },
  {
    id: "bridge-tx",
    title: "Bridge Transaction",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/bridge-tx"
  },
  {
    id: "pending-tx",
    title: "Pending Transaction",
    type: "item",
    icon: <Icon.FileMinus size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/pending-tx"
  }
];

export default navigationConfig;
